import React, { useState } from 'react';

import { Typography, Button, Link } from '@mui/material';
import { BackButton } from '../components/button/button';

import Section from '../components/section';
import Hero, { Text, Tagline, Title } from '../sections/hero/hero';
import Layout from '../components/layout/layout';

export default function IndexPage(props) {
  const isBrowser = typeof window !== 'undefined';

  const [trackingEnabled, setTrackingEnabled] = useState(
    isBrowser && localStorage.plausible_ignore !== 'true',
  );

  const disablePlausibleTracking = () => {
    if (isBrowser) {
      localStorage.plausible_ignore = true;
      setTrackingEnabled(false);
    }
  };

  const enablePlausibleTracking = () => {
    if (isBrowser) {
      delete localStorage.plausible_ignore;
      setTrackingEnabled(true);
    }
  };

  return (
    <Layout
      title="Digital-Beratung aus Berlin"
      noindex="true"
      pathname={props.location.pathname}
    >
      <Hero backgroundColor="antiquewhite">
        <BackButton />
        <Tagline>Datenschutzerklärung</Tagline>
        <Title>So verarbeiten wir Daten</Title>
        <Text>
          Wir haben diese Datenschutzerklärung (Fassung 12.11.2019-311127514)
          verfasst, um Ihnen gemäß der Vorgaben der{' '}
          <Link
            href="https://eur-lex.europa.eu/legal-content/DE/ALL/?tid=1234&amp;uri=celex%3A32016R0679&amp;tid=311127514"
            target="_blank"
            rel="noopener noreferrer"
          >
            Datenschutz-Grundverordnung (EU) 2016/679
          </Link>{' '}
          zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden
          und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite
          haben.
        </Text>
      </Hero>
      <Section>
        <Typography variant="h2" gutterBottom>
          Auswertung des Besucherverhaltens
        </Typography>
        <Typography gutterBottom>
          In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob
          und wie wir Daten Ihres Besuchs dieser Website auswerten. Die
          Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir
          können von Ihrem Verhalten auf dieser Website nicht auf Ihre Person
          schließen.
        </Typography>
        <Typography gutterBottom>
          Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu
          widersprechen erfahren Sie in der folgenden Datenschutzerklärung.
        </Typography>
        <Typography variant="h2" gutterBottom>
          TLS-Verschlüsselung mit https
        </Typography>
        <Typography gutterBottom>
          Wir verwenden https um Daten abhörsicher im Internet zu übertragen
          (Datenschutz durch Technikgestaltung{' '}
          <Link
            href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&#038;tid=311127514"
            target="_blank"
            rel="noopener noreferrer"
          >
            Artikel 25 Absatz 1 DSGVO
          </Link>
          ). Durch den Einsatz von TLS (Transport Layer Security), einem
          Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet
          können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen
          die Benutzung dieser Absicherung der Datenübertragung am kleinen
          Schloßsymbol links oben im Browser und der Verwendung des Schemas
          https (anstatt http) als Teil unserer Internetadresse.
        </Typography>
        <Typography variant="h2" gutterBottom>
          Pipedrive
        </Typography>
        <Typography gutterBottom>
          Wir nutzen Pipedrive als unser CRM-Tool zur Verarbeitung und
          Speicherung der Kontaktdaten.
        </Typography>
        <Typography gutterBottom>
          Bei der Kontaktaufnahme mit uns (per Kontaktformular, E-Mail oder im
          Rahmen eines Downloads) werden die Angaben des Nutzers zur Bearbeitung
          der Kontaktanfrage oder zwecks Ansprache nach einem Download gem. Art.
          6 Abs. 1 lit. b) DSGVO verarbeitet. Die bei der Kontaktaufnahme
          übermittelten Daten werden an Pipedrive gesendet und dort auf Servern
          von Pipedrive gespeichert.
        </Typography>
        <Typography gutterBottom>
          Wir setzen das CRM-System Pipedrive des Anbieters Pipedrive OÜ auf
          Grundlage unserer berechtigten Interessen (effiziente und schnelle
          Bearbeitung von Nutzeranfragen, Bestandskundenmanagement,
          Neukundengeschäft) ein.
        </Typography>
        <Typography gutterBottom>
          Pipedrive OÜ ist eine haftungsbeschränkte Firma nach estnischer
          Gesetzgebung (EU) mit der Anschrift Mustamäe tee 3a, 10615 Tallinn,
          Estland, registriert im Estonian Commercial Register unter dem Code
          11958539. Die Datenschutzerklärung von Pipedrive können Sie hier
          abrufen:{' '}
          <Link
            href="https://www.pipedrive.com/en/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.pipedrive.com/en/privacy
          </Link>
          .
        </Typography>
        <Typography variant="h2" gutterBottom>
          Tracking ausschalten
        </Typography>
        {trackingEnabled ? (
          <Button variant="text" onClick={disablePlausibleTracking}>
            Tracking ausschalten
          </Button>
        ) : (
          <Button variant="text" onClick={enablePlausibleTracking}>
            Tracking einschalten
          </Button>
        )}
      </Section>
    </Layout>
  );
}
